import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import "../css/typography.css";

import Layout from "../components/layout";
import Seo from "../components/Seo";
import colors from "../styles/colors";
import EmptyContent from "../components/EmptyContent";
import styled from "styled-components";

import organigrama from "../images/organigrama.jpg";
import proceso from "../images/proceso.png";

const PoliticaStyled = styled.div`
  margin-top: 50px;
  max-width: 1000px;
  margin: auto auto;

  img {
    max-width: 600px;
  }
  @media (max-width: 1000px) {
    margin-top: 140px;

    img {
      max-width: 100%;
    }
  }
`;

const Pa = styled.p`
  margin-bottom: 2rem;
  font-size: 1.4rem;
  color: ${colors.font};
`;

const Subtitle = styled.h2`
  color: ${colors.darkFont};
  margin-bottom: 2rem;
`;

const List = styled.ul`
  margin: 0 0 3rem 2rem;
  font-size: 1.4rem;
  color: ${colors.font};
`;

const OList = styled.ol`
  margin: 0 0 3rem 2rem;
  font-size: 1.4rem;
  color: ${colors.font};
`;

const Title = styled.h1`
  margin-top: 6rem;
`;

const IndexPage = () => (
  <Layout>
    <Seo title="Grupo Proizd" />
    <PoliticaStyled>
      <EmptyContent
        firstTitle={"GRUPO PROIZD S. A. de C. V."}
        secondTitle={"Política de Integridad"}
      >
        <Pa>
          Proizd establece en su Política de Integridad las reglas y
          responsabilidades a las que todo empleado debe adherirse, mismas que
          permiten prevenir y detectar los siguientes actos: soborno,
          corrupción, regalos e invitaciones, patrocinios y donaciones, fraude,
          lavado de dinero, conflicto de interés, investigación de terceros y
          denuncias o reportes, de actos contrarios a la ética.
        </Pa>
        <Pa>
          El presente documento va dirigido a todos desde dueños, directivos y
          colaboradores de esta. El incumplimiento de la Política de Integridad
          puede dar lugar a la adopción de medidas disciplinarias, que podrán
          variar en función de la gravedad de la situación, mismas que podrían
          ir desde la cancelación de permisos del empleado, aplazamiento de
          promoción, suspensión sin sueldo, rescisión de contrato laboral y/o
          denuncia ante las autoridades correspondientes.
        </Pa>
        <Pa>
          Absolutamente todos somos responsables de resguardar el cumplimiento
          del código de conducta y el resto de las Políticas de Cumplimiento y
          entre ellas Política de Integridad.
        </Pa>
        <Subtitle>Manual de organización</Subtitle>
        <div style={{ textAlign: "center", margin: "3rem 0" }}>
          <img src={organigrama}></img>
        </div>
        <Pa>
          Este documento plasma la organización de la empresa Grupo Proizd, la
          cual establece los niveles jerárquicos, y especifica la división de
          funciones, la interrelación y coordinación que debe existir entre las
          diferentes unidades organizacionales y los entes externos; a efecto de
          lograr el cumplimiento de los objetivos. Constituyen también el marco
          administrativo de referencia para determinar los niveles de
          decisiones. La necesidad de revisar y actualizar los manuales de
          organización surge al modificarse la estructura organizativa y por
          ende las tareas al interior de la empresa.
        </Pa>
        <Subtitle>Antecedentes</Subtitle>
        <Pa>
          Grupo Proizd, se funda en 2019 es una organización integradora de
          soluciones profesionales para las áreas de telecomunicación, redes y
          sistemas de seguridad, basados en la ciudad de Puebla. Somos una
          empresa consolidada, dispuesta a dar soluciones integrales para
          nuestros clientes en el sector público y privado. Con capacitaciones
          certificadas para darle fortaleza a las soluciones propuestas, con
          productos de las marcas y por supuesto, la experiencia en el mercado
          de la integración de proyectos exitosos que cumple con los
          requerimientos de cada cliente.
        </Pa>
        <Subtitle>Objetivo</Subtitle>
        <Pa>
          El objetivo de este código de conducta y confidencialidad es brindar
          un servicio profesional y de calidad que garantice la satisfacción de
          las necesidades de nuestros clientes. Impulsar a un equipo de trabajo
          los valores éticos de honestidad, respeto, eficiencia, eficacia,
          solidaridad, lealtad, respeto y dignidad en la presentación de sus
          servicios profesionales.
        </Pa>
        <Subtitle>Misión</Subtitle>
        <Pa>
          Desarrollar optimas soluciones y de alto valor desde la estrategia a
          la ejecucion en el area de informatica, redes, telecomunicaciones y
          sistemas de seguridad, cubriendo siempre las necesidades e impulsando
          con innovacion la mejora continua de su organizacion.
        </Pa>
        <Subtitle>Visión</Subtitle>
        <Pa>
          Ser la empresa lider en soluciones tecnologicas a nivel estatal,
          fomentando el establecimiento de una atmosfera mas segura y funcional,
          manteniendo la mas alta calidad y excelencia en la implementacion de
          nuestros servicios.
        </Pa>
        <Subtitle>Nuestro compromiso ético</Subtitle>
        <Pa>
          El marco conceptual que inspira lo que somos y queremos ser como
          empresa se creó con base a confianza, esfuerzo y perseverancia
          radicando en el compromiso con nuestros servicios; debido a que
          nuestro éxito este ligado a la responsabilidad de cumplir económica y
          socialmente. Con nuestro código les proporcionamos a nuestros
          colaboradores una guía que resume los principios básicos de nuestra
          actuación, apoyándonos a enfrentar las disyuntivas legales y éticas en
          el trabajo diario. El código de conducta aplica para todos nuestros
          miembros de esta empresa, siendo cada uno responsable de su
          cumplimiento de este.
        </Pa>
        <Title>Funciones y Responsabilidades</Title>
        <Subtitle>
          Los colaboradores en la empresa nos comprometemos a:
        </Subtitle>
        <List>
          <li>
            Contribuir permanentemente a la creación de una cultura de respeto,
            honestidad y profesionalismo.
          </li>
          <li>
            Ser exigentes con nosotros mismos para mejorar nuestro desempeño,
            cuidar nuestra salud y llevar a cabo el auto desarrollo.
          </li>
          <li>
            Hacer uso apropiado y adecuado de la propiedad y de los recursos de
            la empresa y solo usuarios para fines con autorización expresa.
          </li>
          <li>
            Informar a la empresa de posibles conflictos entre nuestros
            intereses particulares y los intereses de la empresa.
          </li>
        </List>
        <Subtitle>La empresa ante sus colaboradores se compromete a:</Subtitle>
        <List>
          <li>
            Elegir, contratar o promover a su personal sobre la base de sus
            conocimientos y habilidades.
          </li>
          <li>
            Ofrecer un entorno de trabajo seguro y proteger a su integridad
            física, mejorando continuamente el entorno laboral a través de
            medida de prevención y salud.
          </li>
          <li>
            Garantizar la confidencialidad de sus datos personales y
            transparencia de su uso.
          </li>
          <li>
            Otorgarles una retroalimentación sobre la percepción que la
            organización obtiene de su trabajo.
          </li>
          <li>
            Colaborar en un marco de apertura y confianza con los representantes
            de los trabajadores, buscando dialogo constructivo y un equilibrio
            de los intereses.
          </li>
        </List>
        <Subtitle>Clientes</Subtitle>
        <Pa>
          Construir acciones y decisiones de compromiso y honestidad con cada
          uno de nuestros clientes.
        </Pa>
        <Pa>
          Respetar la dignidad del personal y de cada uno de nuestros clientes,
          así como sus recursos, su tiempo y sus puntos de vista sin realizar
          distinciones o discriminación por razón de origen, sexo, creencia o
          condición social. Informar con veracidad y oportunidad nuestros
          clientes sobre cualquier aspecto relevante, relativo a nuestros
          productos y servicios.
        </Pa>
        <Subtitle>Proveedores</Subtitle>
        <Pa>
          Seleccionamos a nuestros proveedores bajo criterios claros y
          objetivos. Establecer con nuestros proveedores relaciones
          transparentes, basadas en criterios de justicia, respeto y eficiencia
          que nos permita generar confianza mutua.
        </Pa>
        <Pa>
          Compartir con nuestros proveedores de manera oportuna información
          relevante que afecte nuestra relación, de acuerdo con los lineamientos
          determinados para tales fines.
        </Pa>
        <Subtitle>Dirección y otras actividades externas</Subtitle>
        <Pa>
          Como empresa Grupo Proizd está orgulloso de contar con personal
          altamente capacitado, demostrado a través de su trabajo y sin dejar
          atrás algunas actividades externas.
        </Pa>
        <Pa>
          A cada uno de nuestros clientes se les brinda un excelente servicio,
          con la finalidad de qué siempre se superen todas sus expectativas. Es
          por eso por lo que, en caso de que se haga totalmente lo contrario,
          usted como cliente tiene la libertad de comentarlo con uno de los
          accionistas o bien el director general de la empresa.
        </Pa>
        <Subtitle>Responsabilidad y cumplimiento</Subtitle>
        <Pa>
          Cada uno de nuestros colaboradores tiene la responsabilidad de
          informarse y apegarse a las leyes y reglamentos pertinentes a su
          ámbito laboral, así como a las regulaciones internas orientando sus
          acciones hacia los valores de la empresa y código de conducta y
          confidencialidad.
        </Pa>
        <Pa>
          El jefe fomentara en sus colaboraciones la conducta confirme a las
          reglas, a través de la información periódica y la explicación de las
          obligaciones y marco de decisión aplicables a su área de trabajo.
        </Pa>
        <Subtitle>Dirección general</Subtitle>
        <Pa>
          Asegura el cumplimiento de la misión de la empresa y planes mediante
          la eficiente planificación, programación, organización, dirección,
          control de las operaciones; maximizando los recursos financieros,
          técnicos y humanos que dispone la empresa.
        </Pa>
        <h3> Funciones que le corresponden: </h3>
        <OList>
          <li>Formular el marco estratégico empresarial.</li>
          <li>
            Disponer los sistemas de información necesarios para la toma de
            decisiones estratégicas.
          </li>
          <li>
            Diagnosticar el cumplimiento de los objetivos y planes empresariales
            de corto, mediano y largo plazo, de conformidad con el ordenamiento
            jurídico vigente, y tomar las acciones correctivas pertinentes.
          </li>
          <li>
            Evaluar el cumplimiento de los acuerdos y las acciones correctivas
            correspondientes.
          </li>
          <li>
            Regular en la empresa la investigación, el desarrollo y la expansión
            de las áreas de negocios.
          </li>
          <li>
            Impulsar el fortalecimiento de la imagen externa de la empresa.
          </li>
        </OList>
        <Subtitle>Administración</Subtitle>
        <Pa>
          Formula a nivel de proyectos el desarrollo según el plan estratégico
          de la empresa para determinar la viabilidad técnica, financiera,
          económica, ambiental, social; así como elaborar el análisis de riesgo,
          y participar en los procesos de evaluación.
        </Pa>
        <Pa>
          Promover mejoras para optimizar el sistema nacional de viáticos.
        </Pa>
        <h3>Funciones que le corresponden:</h3>
        <OList>
          <li>
            Elaborar diagnósticos técnicos de la infraestructura existente.
          </li>
          <li>
            Elaborar las estimaciones de demanda de combustibles de largo plazo.
          </li>
          <li>Formular proyectos de investigación a nivel perfil.</li>
          <li>
            Desarrollar la ingeniería preliminar que incluye la ingeniería
            conceptual o básica para la formulación del proyecto.{" "}
          </li>
          <li>Participar en la evaluación de los proyectos de inversión.</li>
          <li>
            Incorporar controles en el proceso de formulación, evaluación y
            aprobación de los proyectos, para garantizar la gestión de riesgo al
            Ambiente, la Salud y la Seguridad.
          </li>
        </OList>
        <Subtitle>Dirección de Ingeniería</Subtitle>
        <Pa>
          Asegurar la innovación de las tecnologías de información y
          comunicación (TIC) que soportan los procesos del negocio,
          fortaleciendo las ventajas competitivas empresariales.
        </Pa>
        <div style={{ textAlign: "center", margin: "3rem 0" }}>
          <img src={proceso}></img>
        </div>
        <h3>Funciones que le corresponden:</h3>
        <OList>
          <li>
            Estimular el uso creativo y la innovación de las TIC para satisfacer
            las necesidades de la empresa.
          </li>
          <li>
            Mantener actualizadas las TIC acorde con las necesidades de la
            empresa y las demandas del entorno, promoviendo el uso racional de
            los recursos existentes.
          </li>
          <li>
            Coordinar la planificación estratégica del TIC, a efecto de
            alinearla con la planificación empresarial y encauzar el desarrollo
            tecnológico y las inversiones según las necesidades del negocio.
          </li>
          <li>
            Establecer y comunicar los estándares de tecnologías basados en
            mejores prácticas que permiten integrar y utilizar la información
            empresarial.
          </li>
          <li>
            Definir un modelo de integración de la información que sirva como
            referencia para el desarrollo de soluciones informáticas y apoyo a
            la toma de decisiones.
          </li>
          <li>
            Proveer las soluciones empresariales en TIC que contribuyan a la
            mejora continua de los procesos de negocio.
          </li>
          <li>
            Facilitar la gestión del cambio y la alfabetización en torno a la
            implementación y el uso de nuevas soluciones y servicios de TIC.
          </li>
          <li>
            Garantizar la disponibilidad y accesibilidad a las aplicaciones y
            servicios informáticos, de conformidad con la normatividad vigente.
          </li>
          <li>
            Coordinar la gestión técnica de las unidades de apoyo informático
            ubicadas en las gerencias y ventas.
          </li>
        </OList>
        <Subtitle>Código de Conducta & Confidencialidad</Subtitle>
        <Pa>
          El código de conducta y confidencialidad de Grupo Proizd expresa el
          compromiso ético de quienes forman parte de la empresa conscientes de
          que los comportamientos se basan en los principios y valores de esta.
          Poniendo en práctica los objetivos, misión visión y valores
          estratégicos de la misma; en conductas que pueden ser observadas en la
          vida personal de sus colaboradores, el documento se presenta con el
          compromiso de ser difundido para que el personal, lo conozca y lo
          adopte para contribuir al fortalecimiento y convicción de que haremos
          nuestros los principios establecidos.
        </Pa>
        <Subtitle style={{ textAlign: "center" }}>Valores</Subtitle>
        <Pa style={{ textAlign: "center" }}>
          Honestidad <br /> Transparencia <br /> Excelencia <br /> Puntualidad
        </Pa>
        <Subtitle style={{ textAlign: "center" }}>Aptitudes</Subtitle>
        <Pa style={{ textAlign: "center" }}>
          Enfoque innovador <br />
          Capacitacion continua <br /> Planeacion creativa <br />
          Optimizacion
        </Pa>
        <Title>Conductas Prohibidas y Obligatorias</Title>
        <Subtitle>1. Sobortno y Corrupción</Subtitle>
        <Pa>
          Grupo Proizd, tiene 0 tolerancia contra el soborno y la corrupción. No
          solo se debe a que esto es ilegal y a que existen penalizaciones
          bastante graves tanto para los individuos como para las empresas,
          además que estos dañan el desarrollo económico y social de los países.
          Grupo Proizd no solo busca un éxito empresarial si no también social a
          través de nuestras actividades.
        </Pa>
        <Pa>
          El soborno y la corrupción pueden adoptar una gran variedad de forma
          es por ello por mínimo que sea el pago sea en especie o monetario los
          integrantes de esta empresa están obligados a informar de manera
          inmediata a su jefe inmediato y recursos humanos, sobre cualquiera
          solicitud de soborno o pago de facilitación.
        </Pa>
        <Subtitle>2. Regalos e Invitación a Terceros</Subtitle>
        <Pa>
          Es muy importante mantener una buena relación comercial con nuestros
          terceros y durante el transcurso de cualquier negociación, proyecto
          y/o acuerdo de relación empresarial pueden darse ocasiones para
          intercambiar regalos o invitaciones. Sin embargo, es fundamental que
          cualquier tipo de regalo o invitación ofrecido, proporcionado o
          recibido por un empleado sea legítimo, razonable y proporcional y no
          pueda interpretarse como soborno o incentivo. Hemos decidido
          proporcionar un conjunto de principios, confiando en el buen sentido y
          comportamiento razonable y responsable de los empleados mismos que
          deberán cumplir con expectativas y se usaran controles y auditorias
          para controlar el cumplimiento.v
        </Pa>
        <Subtitle>3. Patrocinio y Donadores</Subtitle>
        <Pa>
          El patrocinio puede constituir un importante canal de promoción para
          nuestra empresa, mientras que las donaciones son uno de los diversos
          métodos mediante los que apoyamos a las comunidades en las que
          trabajamos. Sin embargo, el patrocinio y las donaciones también
          presentan el riesgo de que se utilicen para fines corruptos para
          obtener influencias empresariales indebidas. Grupo Proizd no tolera ni
          permite este tipo de comportamiento. También es importante recordar
          que no hacemos donaciones empresariales a partidos políticos.
        </Pa>
        <Subtitle>4. Colaboración con Funcionarios Gubernamentales</Subtitle>
        <Pa>
          Como parte de las operaciones empresariales habituales, se pueden
          organizar y llevar a cabo reuniones con funcionarios gubernamentales
          para hablar sobre temas relacionados con la actividad empresarial de
          Grupo Proizd.
        </Pa>
        <Pa>
          Para asegurar que estas reuniones se lleven a cabo de manera adecuada
          y para minimizar cualquier acto incorrecto o corrupción real o
          percibida, deben realizarse de forma abierta y transparente. Cualquier
          invitación que se ofrezca debe estar vinculada al propósito de la
          reunión y solo debe ser nominal y acorde con los usos y costumbres,
          así como estar aceptada de acuerdo con las normas establecidas dentro
          de la empresa. Siempre que sea posible, las reuniones deben tener
          lugar en oficinas gubernamentales oficiales.
        </Pa>
        <Pa>
          Para asegurar la transparencia y el control de los contactos con
          funcionarios del gobierno, se debe conservar toda la documentación
          pertinente, incluidas las autorizaciones y aprobaciones internas y
          externas requeridas.
        </Pa>
        <Pa>
          Además de la orientación específica que se describe en esta y otras
          secciones de este documento, todos aquellos contactos en los que esté
          previsto que se produzca una transferencia de valor deben ser
          previamente aprobadas por el jefe de departamento y director de esta.
          La documentación conservada debe detallar el objetivo de contacto
          mantenido, la fecha, los participantes (indicando el cargo o función
          desempeñada por cada uno de ellos) y el valor monetario transferido.
        </Pa>
        <Subtitle>5. Cabildeo</Subtitle>
        <Pa>
          La colaboración con grupos de políticas públicas y grupos de promoción
          constituye un elemento fundamental de nuestro trabajo y es importante
          elegir a los grupos y asociaciones adecuados. Aunque no existe una
          definición universalmente aceptada de lo que se consideran actividades
          de cabildeo, se reconocen generalmente como una parte legítima y
          necesaria de nuestros procesos democráticos en los que los individuos,
          asociaciones y entidades corporativas quieren influir de manera
          razonable en las decisiones que puedan afectarles de algún modo. Tales
          grupos son generalmente actores externos no gubernamentales u
          organizaciones sin ánimo de lucro, como asociaciones comerciales,
          organizaciones sindicales, grupos de interés o los grupos de
          reflexión.
        </Pa>
        <Pa>
          En algunos países, las actividades de cabildeo están reguladas por la
          ley o por ciertos procesos de «buenas prácticas» establecidos. Además,
          aplica determinados criterios, junto con su Política de integridad y
          su Código de conducta, que deben aplicarse en la selección de nuestros
          socios en actividades de cabildeo y a la hora de garantizar la
          transparencia de todos los contactos que mantenga nuestra empresa.
        </Pa>
        <Pa>
          Se deben considerar los siguientes criterios antes de comprometerse
          con algún grupo (o individuo) que trabaje en el ámbito de la promoción
          o las políticas públicas:
        </Pa>
        <List>
          <li>
            ¿La misión del grupo está alineada con la misión de Grupo Proizd,
            con nuestros valores éticos y nuestras normas de integridad?
          </li>
          <li>
            ¿Tiene el grupo una buena reputación y se le considera una
            organización con credibilidad?
          </li>
          <li>
            ¿Se considera que el trabajo que lleva a cabo este grupo es de alta
            calidad?
          </li>
          <li>
            ¿Tenemos claros los beneficios y el valor de la colaboración
            propuesta y es similar a nuestros acuerdos de pertenencia a otros
            grupos?
          </li>
          <li>
            ¿Se rige el grupo por normas éticas y de integridad propias, y tiene
            un marco de gobierno creíble? ¿Quiénes son los demás miembros del
            grupo?
          </li>
          <li>¿Nos sentimos cómodos con ellos?</li>
        </List>
        <Pa>
          Todas las actividades y grupos/individuos de cabildeo que colaboren
          por Grupo Proizd deben comunicarse y ser aprobados previamente por el
          responsable del área de relaciones con gobierno. El responsable del
          área de relaciones con gobierno debe mantener un registro de todos los
          compromisos a nivel grupal.
        </Pa>
        <Subtitle>6. Lavado de Dinero</Subtitle>
        <Pa>
          El lavado de dinero constituye un delito en la mayoría de los países y
          en algunos casos, el hecho de no informar de la sospecha de lavado de
          dinero también puede constituir un delito. En Grupo Proizd rechazamos
          cualquier intento de utilizar nuestra empresa para el lavado de dinero
          y solo trabajamos con clientes, terceros y socios que sean legales.
        </Pa>
        <Pa>
          Debemos asegurarnos de que contamos con un documento oficial de
          identificación de los terceros pertinentes antes de iniciar nuestra
          relación empresarial con ellos; asimismo, debemos tratar de determinar
          quién es el titular real del tercero.
        </Pa>
        <Subtitle>7. Conflicto de Interés</Subtitle>
        <Pa>
          Puede producirse un conflicto de interés cuando los intereses
          personales, sociales, económicos o políticos de un empleado puedan
          influir (o parezcan influir) en la lealtad del empleado o su capacidad
          para llevar a cabo sus funciones de manera objetiva.
        </Pa>
        <Pa>
          Todas las decisiones empresariales que se adopten deben estar basadas
          en criterios claros, objetivos y transparentes. Cada vez que nos
          encontremos en una situación potencialmente conflictiva debemos
          preguntarnos a nosotros mismos: «¿confiaría en el juicio de alguien en
          mi posición que tuviera el mismo posible conflicto de interés? Lo más
          importante es que declaremos los posibles conflictos y que se
          gestionen correctamente con el fin de mitigarlos.
        </Pa>
        <Subtitle>8. Investigación de Antecedentes de Terceros</Subtitle>
        <Pa>
          Todas las empresas necesitan colaborar con terceros para llevar a cabo
          sus actividades. Sin embargo, la colaboración con un socio corrupto o
          no ético expondría a esta a riesgos significativos de cumplimiento,
          financieros y de reputación. Por lo tanto, es fundamental que sepamos
          con quién estamos tratando, que estemos seguros de que nuestros
          asociados comparten los mismos principios de negocio que nosotros y
          que los términos contractuales y de remuneración sean adecuados para
          protegernos. Para supervisar y controlar nuestros riesgos de manera
          apropiada, nuestro objetivo es llevar a cabo el control antes de
          iniciar una colaboración con terceros relevantes y, posteriormente,
          repetir dicho control al cabo de un máximo de tres años. Controles
          prácticos adicionales: Además de comprobar que los posibles terceros
          con los que trabajamos comparten nuestros principios empresariales,
          también debemos asegurarnos de llevar a cabo los controles prácticos
          apropiados para prevenir conductas que no sean éticas. Estos incluyen:
          Exigir a los terceros con los que vayamos a trabajar que firmen
          nuestro Código de conducta para Socios de negocio; Reafirmar de que
          los terceros con los que vayamos a trabajar hayan firmado un acuerdo
          con nosotros con arreglo a los requisitos de las autoridades
          competentes.
        </Pa>
        <Subtitle>9. Fraude</Subtitle>
        <Pa>
          Los empleados deben informar de cualquier sospecha de fraude o
          conducta financiera indebida inmediatamente, ya sea a los
          representantes de finanzas local, recursos humanos, departamento legal
          o de calidad. Si las opciones anteriores no resultan apropiadas,
          también pueden realizar su denuncia/queja a través de las dependencias
          correspondientes.
        </Pa>
        <Subtitle>Medios de Difusión</Subtitle>
        <Pa>
          Grupo Proizd da a conocer sus políticas de Integridad al momento de
          contratar a sus personas, así como al momento de hacer negocio con
          terceros y/o clientes, de igual manera se proporciona copia de esta a
          cada uno de nuestros empleados para que se tenga presente en cada
          momento y se lleve a cabo con la mejor y mayor responsabilidad para
          todos.
        </Pa>
        <Subtitle>Responsable de Controlar, Vigilar y Auditar</Subtitle>
        <Pa>
          El C. Daniel Rojas Ramirez es la persona encargada del controlar,
          vigilar y asegurar que se cumpla de manera efectiva y eficiente el
          proceso de las políticas dentro y fuera de Grupo Proizd, comunica de
          manera oportuna los objetivos y alcance las mismas y dispone de guías
          y manuales que se proporcionan para hacer consciente a todo el
          personal dentro de la empresa, así como se envía información de manera
          electrónica y se hace pública dicha información en nuestro portal web.
        </Pa>
        <Subtitle>Procedimientos</Subtitle>
        <Pa>
          El Oficial de Cumplimiento es responsable de establecer un sistema
          adecuado y eficaz de control y vigilancia que examine de manera
          constante y periódica el cumplimiento de los estándares de integridad
          en la organización.
        </Pa>
        <Pa>
          Su objetivo es garantizar el diseño, implementación, verificación y
          actualización de políticas, procesos, lineamientos y controles que
          propicien el cumplimiento de la normatividad interna y externa
          aplicable tanto a Grupo Proizd como a sus colaboradores en relación
          con sus actividades corporativas y de negocios.
        </Pa>
        <Pa>Entre sus funciones se encuentran:</Pa>
        <OList>
          <li>
            Fomentar en los Colaboradores una cultura de cumplimiento a la
            normatividad interna y externa aplicable, enfatizando a todos los
            niveles del personal la importancia de su implementación y
            cumplimiento, así como de la integridad en su conducta profesional,
            evitando prácticas que puedan generar incentivos u oportunidades
            para la realización de actividades inapropiadas, delictivas,
            deshonestas, engañosas, indebidas, ilegales o que atenten en contra
            de los principios y valores corporativos.
          </li>
          <li>
            Definición de las actividades y mecanismos internos de prevención y
            detección de incumplimientos, incluyendo, las medidas de evaluación
            para cada área, y los controles de seguimiento respecto de
            eventuales incumplimientos a la normatividad interna y externa
            aplicables a Grupo Proizd. Las actividades de prevención deben
            asegurar que las áreas con posibles conflictos de interés se
            encuentren identificadas para efectos de ejecutar las medidas que
            resulten necesarias y efectivas para prevenir y sancionar las
            conductas que comprometan la imparcialidad de los colaboradores en
            el desempeño ético, eficiente y objetivo de sus funciones.
          </li>
          <li>
            Supervisar el cumplimiento de las disposiciones normativas externas
            e internas aplicables, a través, de manera enunciativa más no
            limitativa, del análisis de los cambios y actualizaciones
            regulatorias que les apliquen, atención de oficios y demás
            información emitida por las autoridades.
          </li>
          <li>
            Implementación de los mecanismos internos de seguimiento y
            remediación que permitan la comprensión de la situación reportada,
            proveyendo los resultados y recomendaciones derivados de la función
            de cumplimiento con el propósito de garantizar la aplicación de las
            medidas correctivas, preventivas y de mejora que correspondan.
          </li>
          <li>
            Establecer mecanismos de información y comunicación interna,
            implementando líneas claras y oportunas de comunicación y reporte.
            Estas líneas de comunicación al interior de esta deben estimular el
            reporte de faltas administrativas, faltas a la normatividad interna
            y externa aplicable, así como cualquier problema, reclamación,
            inquietud, duda, comentario o sugerencia respecto a las actividades
            cotidianas que realizan los colaboradores en el desempeño de sus
            funciones, esto con el propósito de evitar que los colaboradores
            oculten información, así como fomentar un ambiente laboral de
            cumplimiento normativo y mejora corporativa continua. Para ello, los
            mecanismos de información y comunicación deben prever, cuando la
            urgencia o relevancia de la situación lo amerite, la posibilidad del
            reporte directo sin observar necesariamente la línea de mando.
            Cualquier acción que pudiera derivar en una violación a esta
            Política o a cualquier otra normativa interna o externa aplicable,
            debe ser reportada al Oficial de Cumplimiento Regulatorio quien
            escalará los temas de mayor riesgo a la Gerencia correspondiente o
            en su caso directamente a la Dirección General.
          </li>
        </OList>
        <Subtitle>Sistemas Adecuados de Denuncia</Subtitle>
        <Pa>
          La denuncia o queja sobre una irregularidad consiste en que un
          empleado de Grupo Proizd o un tercero relacionado, informe a la
          compañía de la sospecha de presuntas irregularidades. Para nuestra
          empresa, el alcance de una denuncia de irregularidades engloba la
          conducta requerida según lo establecido en nuestro Código de Ética, en
          la presente Política de Integridad o cualquier normatividad aplicable,
          ya sea interna o externa. Queremos conocer inmediatamente cualquier
          infracción o posible infracción de nuestros principios empresariales,
          cualquier conducta ilícita, malversación de fondos y actividad que
          suponga o pueda suponer un peligro para cualquier persona que trabaje
          para nuestra empresa. Siempre animando a nuestro equipo de
          colaboradores a que expongan cualquier tipo de preocupación. Como
          parte del compromiso de Proveedora Integral de Empresas en establecer
          los mecanismos necesarios y adecuados para la confirmación de un
          sistema de Ética, se cuenta con un esquema multimedios de denuncia
          imparcial para registrar, documentar y, en su caso, investigar
          violaciones al Reglamento Interior de Trabajo, a la presente política,
          así como a la regulación aplicable. Dichos canales de denuncia se
          encuentran debidamente publicados dentro del Código Conducta y
          Confidencialidad y en los medios de comunicación corporativos para que
          puedan ser consultados por los colaboradores, clientes y proveedores,
          en cualquier momento. Dado que el presente no cubre todas las
          circunstancias que pueden llegar a presentarse, en caso de existir
          duda o sospecha de algún acto ilícito o violación al presente
          documento, el colaborador se acerque con el Oficial de Cumplimiento
          con el fin de determinar la situación y dar respuesta oportuna. Todo
          reporte e información, sobre las violaciones e infracciones al
          presente código que le sean comunicadas al Oficial de Cumplimiento se
          manejarán con la mayor discreción y confidencialidad. Sin embargo, si
          el deseo del denunciante el permanecer completamente anónimo se ponen
          a su disposición los medios siguientes:
        </Pa>
        <List>
          <li>Teléfono: (221) 662 5523</li>
          <li>Correo: admin-servicios@proizd.com.mx</li>
        </List>
        <Subtitle>Sanciones</Subtitle>
        <Pa>
          Las infracciones a las normas contenidas en las Políticas de
          Integridad de Grupo Proizd resultarán en medidas disciplinarias que
          variarán, dependiendo de las circunstancias y gravedad de cada caso,
          desde una amonestación verbal hasta la terminación de la relación
          laboral o comercial con el infractor, con base en lo señalado en el
          Código de Ética, y el Reglamento Interior de Trabajo, y de ser
          necesario lo establecido en las leyes aplicables correspondientes.
        </Pa>
        <Subtitle>Sistemas y Procesos de Entrenamiento y Capacitación</Subtitle>
        <Pa>
          Grupo Proizd implementa un programa de capacitación (inducción) para
          todos los colaboradores de nuevo ingreso. Dicho programa además de
          abarcar temas como condiciones generales de contratación y procesos
          específicos del área a laborar, también incluye los temas relacionados
          a conflictos de interés, obsequios, manejo y resguardo de información,
          obligaciones como empleados y las políticas y reglamentos aplicables
          referentes a cuestiones de ética y cumplimiento. Del mismo modo,
          contará con herramientas a través de las cuales, se difundan,
          socialicen y expliquen a los colaboradores, las políticas, normas,
          procedimientos y códigos que les son aplicables. Estas herramientas
          serán accesibles para todos los colaboradores, salvo algunas
          excepciones por cuestiones de confidencialidad.
        </Pa>
        <Subtitle>Objetivo</Subtitle>
        <Pa>
          Diseñar un modelo de entrenamiento para el área de operación de la
          empresa en estudio, basado en un análisis de benchmarking, con el fin
          de estructurar y estandarizar el entrenamiento que reciben los
          operadores para disminuir los riesgos asociados a la operación del
          sistema.
        </Pa>
        <Title>Políticas de Reclutamiento</Title>
        <Pa>
          El proceso de Recursos Humanos referente al reclutamiento y selección
          de nuevos colaboradores tiene como objetivo principal el evitar la
          incorporación de personas que puedan generar un riesgo al cumplimiento
          de las políticas, lineamientos, valores y normatividad interna y
          externa aplicable a nuestra empresa.
        </Pa>
        <Subtitle>1. Análisis de necesidades</Subtitle>
        <Pa>
          El primer paso que tomamos es el análisis de las necesidades de la
          empresa. Detallar el puesto que hay que cubrir, las funciones que
          desarrollara y qué papel tiene en el desarrollo de la organización y
          en el logro de los objetivos.
        </Pa>
        <Subtitle>2. Reclutamiento</Subtitle>
        <Pa>
          En esta fase se busca a los mejores aspirantes para las vacante a
          cubrir. Para ello se desarrollan diversas acciones para la búsqueda
          del perfil requerido; como publicaciónes de ofertas laborales o a
          través de compu trabajo, indeed y linkedln. Siempre transmitiendo una
          oferta y requerimientos claros y una propuesta competitiva.
        </Pa>
        <Subtitle>3. Selección</Subtitle>
        <Pa>
          En este proceso seleccionamos al aspirante que mejor encaje en el
          puesto a cubrir, tras una serie de pruebas técnicas, exámenes
          psicométricos y una o varias entrevistas, se selecciona al candidato
          que mejor responda a las necesidades del puesto y a la cultura laboral
          de la empresa.
        </Pa>
        <Subtitle>4. Incorporación</Subtitle>
        <Pa>
          La última etapa de nuestro proceso de reclutamiento y selección de
          personal es la incorporación del trabajador a la organización. Para
          una exitosa suma de talento, planificamos cada paso de la
          incorporación, para que queden claras las funciones a desarrollar y
          las reglas laborales.
        </Pa>
        <Subtitle>Clausulas o Políticas de no Discriminación</Subtitle>
        <Pa>
          Como lo marca nuestro Código de Ética y Conducta, en ningún momento
          debe tolerar la discriminación de persona alguna motivada por origen
          étnico o nacional, el género, la edad, las discapacidades, la
          condición social, la condición de salud, la religión, las opiniones,
          las preferencias sexuales, el estado civil o cualquier otra que atente
          contra la dignidad humana y tenga por objeto anular o menoscabar los
          derechos y libertadas de las personas.
        </Pa>
        <Subtitle>No Incumpliento de la Política de Integridad</Subtitle>
        <Pa>
          En este proceso del área de Recursos Humanos, se establece que las
          personas que ingresen como nuevos colaboradores deben cumplir con los
          requerimientos mínimos del perfil de puesto en términos de calidad
          técnica, profesional, aptitudes, experiencia y habilidades
          específicas. Del mismo modo establece que se realizarán, de acuerdo
          con la posición y el nivel jerárquico y de responsabilidades,
          evaluaciones en donde se podrá valorar, adicional al desempeño del
          colaborador, su honestidad y compromiso con la organización.
        </Pa>
        <Subtitle>Mecanismos de Transparencia y Publicidad</Subtitle>
        <Pa>
          Grupo Proizd deberá establecer mecanismos que aseguren en todo momento
          la transparencia y la publicidad de sus intereses corporativos, para
          lo cual cumple con los requerimientos de divulgación de información.
          De igual manera, reafirmamos nuestro compromiso para cooperar con las
          autoridades pertinentes, que tengan a su cargo la investigación de
          sobornos y actos de corrupción, así como la conducción de acciones
          penales, en caso de ser requeridos por la autoridad. Asi mismo, la
          compañía se esfuerza en la implementación de mecanismos de
          autorregulación para fortalecer los estándares y prácticas antisoborno
          y manejo de conflictos de interés en el sector de salud. Del mismo
          modo, estamos comprometidos con el cumplimiento de las leyes
          aplicables en contra del lavado de dinero, corrupción y financiamiento
          al terrorismo.
        </Pa>
        <Subtitle>Tipo de Información que se Publica</Subtitle>
        <Pa>
          Sin embargo, no podremos publicar información vulnerable o documentos
          que contengan información sobre nuestras operaciones o secretos
          institucionales, debido a que forman parte de lo que consideramos como
          ventajas competitivas ante nuestros competidores, o cualquier otro
          tercer que pueda obtener algún beneficio personal con el uso de
          nuestra información. Del mismo modo, consideramos como información
          confidencial, todos aquellos documentos que el personal entrega al
          área de Recursos Humanos para la integración de su expediente laboral.
        </Pa>
        <Subtitle>Medio para Hacer Publicos Nuestros Interéses</Subtitle>
        <Pa>
          La presente Política, así como nuestro Código de Conducta y
          Confidencialidad, deben de ser difundidas a través de los medios
          corporativos, incluido nuestro sitio web www.proizd.com.mx, con el
          objetivo de que pueda ser consultada por todos los colaboradores de la
          compañía. Como seguimiento, llevamos a cabo diversas actividades para
          fomentar la ética, transparencia e integridad, de acuerdo con las
          legislaciones tanto nacionales como internacionales
        </Pa>
      </EmptyContent>
    </PoliticaStyled>
  </Layout>
);

export default IndexPage;
